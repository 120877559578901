import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk  from 'redux-thunk';

import reducers from 'reducers';

let composer = null;

if(process.env.NODE_ENV === 'development' && window.chrome) {
    composer = compose(
        applyMiddleware(reduxThunk),
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );
} else {
    composer = compose(applyMiddleware(reduxThunk)); 
}

const store = createStore(reducers, 
    {
        status: {modifiedTactics: []}
    }, composer);

export default store;